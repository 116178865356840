<template>
    <layout-full>
        <router-view />
    </layout-full>
</template>

<script>
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import store from "@/store";

import {
  AUTH_STORE_NAMESPACE,
  SET_ORG_SETTINGS_ACTION
} from "@/modules/authnz/models/store";

import {useOrganizationApi} from "@/modules/authnz/composables/use-organization-api";

import { onMounted } from "@vue/composition-api";

export default {
  components: {
    LayoutFull,
  },
  setup(props, context) {
    const publicAccessToken = context.root.$route.query.token;
    const {getOrganizationSettings} = useOrganizationApi();

    onMounted(async() => {
      try {
        let settings = await getOrganizationSettings(publicAccessToken);
        store.dispatch(`${AUTH_STORE_NAMESPACE}/${SET_ORG_SETTINGS_ACTION}`, {settings});
      } catch (e) {
        console.error('Error: trying loading org config:', e)
      }
    });
  },
};
</script>
